'use strict'

export function preparesSerializableRequest (parametersRequest, isFile = false) {
  const qs = require('qs')
  if (isFile) {
    return {
      params: parametersRequest,
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob',
      paramsSerializer: params => qs.stringify(params)
    }
  } else {
    return {
      params: parametersRequest,
      paramsSerializer: params => qs.stringify(params)
    }
  }
}
