import { CONFLICT } from '../../../../../shared/constants/httpStatus'
import { SHOW_ALERT, ADD_COUNTER_REQUEST, SUBTRACT_COUNTER_REQUEST } from '@/store/mutations-types'
import {
  URL_ALL_PROJECTS,
  USERNAME_FIELD_SEARCH,
  ORDER_TYPE,
  URL_FILTERED_USERS,
  URL_TENDER_ASSIGNED_TO_USER,
  URL_UPDATE_TENDER_ASSIGNED_TO_USER
} from './ProjectBindUserConstant'
import {
  preparesSerializableRequest
} from '@/shared/utilityService'
import AppTextFieldWithValidation from '@/components/uiComponents/inputs/AppTextFieldWithValidation'
import handleErrorMixin from '@/mixins/handleErrorMixin'

export default {
  name: 'ProjectBindUser',
  mixins: [ handleErrorMixin ],
  components: { AppTextFieldWithValidation },
  data () {
    return {
      usersList: null,
      projectList: null,
      dataTenderBindToUserFound: null,
      selectedTenderList: [],
      selectedUser: {},
      userFieldSearch: null
    }
  },
  created () {
    this.getProjectsList()
    this.getFilteredUsersList(null)
  },
  methods: {
    validateForm () {
      if (this.selectedUser) {
        let requestPayload = {
          tenderToUnbindList: this.walkListFindingProjectsToUnbind(this.dataTenderBindToUserFound, this.selectedTenderList),
          tenderToBindList: this.walkListFindingProjectsToBind(this.dataTenderBindToUserFound, this.selectedTenderList)
        }
        this.updateBindProjectToUser(this.selectedUser.id, requestPayload)
        return
      }
      this.showAlertMethod(this.$t('messages.unselect_user'), 'warning')
    },
    async changeValueUserList () {
      if (this.selectedUser) {
        await this.getTendersAssingedToUser(this.selectedUser.id)
      }
    },
    getProjectsList () {
      this.$store.commit(ADD_COUNTER_REQUEST)
      this.axios.get(URL_ALL_PROJECTS).then((response) => {
        this.projectList = response.data
      }).catch((error) => {
        if (error.response.status === CONFLICT) {
          this.showAlertMethod(error.response.data.errorMessage, 'error')
        } else {
          this.$_handleErrorMixin_generateError(error)
        }
      }).finally(() => {
        this.$store.commit(SUBTRACT_COUNTER_REQUEST)
      })
    },
    async getFilteredUsersList (filterValue) {
      let requestBody = {
        'filterValue': filterValue,
        'orderType': ORDER_TYPE,
        'searchFields': USERNAME_FIELD_SEARCH,
        'page': 1,
        'maxResult': 5
      }
      this.$store.commit(ADD_COUNTER_REQUEST)
      await this.axios.get(`${URL_FILTERED_USERS}`,
        preparesSerializableRequest(requestBody))
        .then(respuesta => {
          this.usersList = respuesta.data
          this.selectedUser = this.usersList && this.usersList.length > 0 ? this.usersList[0] : null
          if (this.selectedUser) {
            this.getTendersAssingedToUser(this.selectedUser.id)
          }
        }).catch(error => {
          this.$_handleErrorMixin_generateError(error)
        }).finally(() => {
          this.$store.commit(SUBTRACT_COUNTER_REQUEST)
        })
    },
    async getTendersAssingedToUser (userId) {
      this.$store.commit(ADD_COUNTER_REQUEST)
      await this.axios.get(`${URL_TENDER_ASSIGNED_TO_USER}${userId}`)
        .then(respuesta => {
          this.dataTenderBindToUserFound = respuesta.data
          this.matchingProjecListWithInfoUser()
        }).catch(error => {
          this.$_handleErrorMixin_generateError(error)
        }).finally(() => {
          this.$store.commit(SUBTRACT_COUNTER_REQUEST)
        })
    },
    matchingProjecListWithInfoUser () {
      this.selectedTenderList = []
      this.dataTenderBindToUserFound.forEach(element => {
        let tenderEcnontrado = this.projectList.filter(project => project.id === element.tenderId)
        if (tenderEcnontrado.length > 0) {
          this.selectedTenderList.push(tenderEcnontrado[0])
        }
      })
    },
    redirectToHome () {
      this.$router.push({ name: 'Home' })
    },
    search () {
      this.getFilteredUsersList(this.userFieldSearch)
    },
    showAlertMethod (mensaje, tipoAlerta) {
      this.$store.commit(SHOW_ALERT, {
        type: tipoAlerta,
        text: mensaje,
        show: true
      })
    },
    async updateBindProjectToUser (userId, requestPayload) {
      this.$store.commit(ADD_COUNTER_REQUEST)
      await this.axios.put(`${URL_UPDATE_TENDER_ASSIGNED_TO_USER}${userId}`, requestPayload)
        .then(respuesta => {
          this.showAlertMethod(this.$t('messages.update_tender_bind_user_success'), 'success')
        }).catch(error => {
          this.$_handleErrorMixin_generateError(error)
        }).finally(() => {
          this.selectedUser = null
          this.selectedTenderList = []
          this.$store.commit(SUBTRACT_COUNTER_REQUEST)
        })
    },
    walkListFindingProjectsToUnbind (projectsAssignedNow, newSelectionProjects) {
      let resultList = []
      projectsAssignedNow.forEach(projectAssignedNow => {
        let result = newSelectionProjects.filter(newSelectionProject => newSelectionProject.id === projectAssignedNow.tenderId)
        if (result.length === 0) {
          resultList.push(projectAssignedNow.tenderUserViewId)
        }
      })
      return resultList
    },
    walkListFindingProjectsToBind (projectsAssignedNow, newSelectionProjects) {
      let resultList = []
      newSelectionProjects.forEach(newSelectionProject => {
        let result = projectsAssignedNow.filter(projectAssignedNow => newSelectionProject.id === projectAssignedNow.tenderId)
        if (result.length === 0) {
          resultList.push(newSelectionProject.id)
        }
      })
      return resultList
    }
  },
  watch: {
    selectedUser () {
      if (!this.selectedUser) {
        this.selectedTenderList = []
      }
    }
  }
}
