
const PUBLIC_BASE_URL = process.env.VUE_APP_API_URL

export const URL_ALL_PROJECTS = `${PUBLIC_BASE_URL}service/tender`
export const URL_FILTERED_USERS = `${PUBLIC_BASE_URL}service/user`
export const URL_TENDER_ASSIGNED_TO_USER = `${PUBLIC_BASE_URL}service/tender/assigned-tender-user/`
export const URL_UPDATE_TENDER_ASSIGNED_TO_USER = `${PUBLIC_BASE_URL}service/user/bind-tender/`

export const USERNAME_FIELD_SEARCH = 'username'
export const ORDER_TYPE = 'asc'
